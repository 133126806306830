import React, { useEffect, useRef } from "react";
import "./intro.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Intro = ({ bigtext,bigtitle }) => {
  const triggerRef = useRef(null);
  
  useEffect(() => {
    const element = triggerRef.current;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: `${element.querySelector(".intro")}`,
        markers: false,
        start: "top center+=100",
        end: "+=700" // end after scrolling 500px beyond the start
        // scrub: 1
      }
    });
    tl.to(".explore", {
      y: "-100px",
      duration: 1,
      stagger: 0.2,
      opacity: 1,
      delay: 0.5
    });
  }, []);
  return (
    <div className="intro" ref={triggerRef}>
      <div className="intro__wrap">
        <div className="intro__left">
          <h1 className="explore">
           {bigtitle}
          </h1>
          <p
            className="explore pb-2"
            dangerouslySetInnerHTML={{
              __html: bigtext != "" ? bigtext : ""
            }}
          />
        </div>
        <div className="intro__right explore">
          <div className="black-box">
            <span className="box-title">18+</span>
            <span className="box-desc">Years of Experience</span>
          </div>
        </div>
      </div>
      <div className="intro__orange">
        <div className="orange-box explore">
          <span className="box-title">
            100+ 
            <small></small>
          </span>
          <span className="box-desc">Projects Delivered</span>
        </div>
      </div>
    </div>
  );
};

export default Intro;
