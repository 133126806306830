import React, { useState, useEffect,useRef } from "react";
import "./aboutdetail.css";
import Data from "../../data/data.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Aboutdetail = ({ bigtext, imgOne, imgTwo, imgthree }) => {
  let path = window.location.pathname.slice(1);
  const aboutRef = useRef();
  useEffect(() => {
    scrollEachItem(".anim-about");
  }, []);

  const scrollEachItem = (details) => {
    const items = document.querySelectorAll(details);
    items.forEach(item => {
      console.log(item);
      const gsapData = gsap.from(item,{
        scrollTrigger:{
          trigger: item,

        },
        y : "100px",
        opacity:0,
        ease: "power1.inOut",
        duration: 1,
        delay: 1
      });

      return gsapData;
      
    })


  }

  return (
    <section className="aboutDetail " ref={aboutRef}>
      <div className="aboutDetail__content">
        {/* <div className="aboutDetail__text">
          <h2 className="heading-24 b30"></h2>
          <p className="p20"></p>
        </div> */}
        <h1 className="anim-about">Who we are?</h1>
        <p className="introAbout anim-about">Pixmestudio is a digital design studio based in Dubai. We build hybrid design systems with immersive user experience that seamlessly blend brand expression and product experience, bringing brands to technology and making people love to interact with.
</p>
<div className="imageDetails anim-about">
          <img src={imgOne} alt={"Welcome to Pixmestudio"} width="100%" className="imgSmall" />
          </div>

          <div className="row d-flex">
        <div className="col-md-6 pr-4">
         
          <p className="smallText anim-about">For more then a decade our team has had the privilege of working with global brands and inspiring startups to tell brand-led stories and shape digital identities. We creates an exceptional visualization and thought-out functionality.</p>
        </div>
        <div className="col-md-6 text-right">  <img src={imgthree} alt={"Welcome to Pixmestudio"} width="100%" className="smallimagesMax anim-about" /></div>
      </div>

      <h2 className="heading-primary mt-96 anim-about">We believe that all good things are achieved by those who are willing to put in passion, courage and craftsmanship.</h2>

      <div className="row d-flex  mt-96">
       
        <div className="col-md-6 text-left column-small">  <img src={imgTwo} alt={"Welcome to Pixmestudio"} width="100%" className="smallimagesMax anim-about" /></div>
      
      <div className="col-md-6 pr-4 column-large ">
         
         <p className="smallText anim-about">We believe, our clients deserve to be remarkable in their businesses and we like to see our clients as partners and we do our best to deliver the product they and their users are 100% satisfied with. Agile principles, ongoing communication, transparency are the cornerstones of each of our projects.</p>
       </div>
       </div>
        {/* <div className="aboutDetail__text">
        <img src={imgOne} alt={"Welcome to Pixmestudio"} width="100%" className="details" />
          <p
            className="aboutDetail__text aboutDetail__headings details"
            dangerouslySetInnerHTML={{
              __html: bigtext != "" ? bigtext : "Our Story"
            }}
          />
         
        </div> */}
      </div>
      
    </section>
  );
};

export default Aboutdetail;
