import React from "react";
import "./footer.css";
import arrowGrey from "../../images/arrow-grey.svg";
import { Link } from "react-router-dom";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoYoutube
} from "react-icons/io5";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fuild footer__grid">
        <div className="footer__content">
          <div className="heading-40">
          Got a project in hand?
            
          </div>
          <Link to="/contact" className="link__primary">
        
          
          Let’s Discuss
            <span>
              <img src={arrowGrey} alt="Grey" />
            </span>
         
          </Link>
        </div>
        <div className="footer__links">
          <div className="footer__item">
            <ul>
              <li>
                <a href="#">Work</a>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <a href="#">Engagements</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
            </ul>
          </div>
          <div className="footer__item">
            <ul>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms of Service</a>
              </li>
              <li>
                <a href="#">Customer Terms</a>
              </li>
              <li>
                <a href="#">Designer Terms</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__copyright container-fuild">
        <div className="logo_footer">Pixmestudio</div>
        <div className="copyright__text">
          © {new Date().getFullYear()} Pixmestudio. All rights reserved
        </div>
        {/* <div className="footer__social">
          <a href="#">
            <IoLogoFacebook color="#000" size="1.4rem" />
          </a>
          <a href="#">
            <IoLogoTwitter color="#000" size="1.4rem" />
          </a>
          <a href="#">
            <IoLogoLinkedin color="#000" size="1.4rem" />
          </a>
          <a href="#">
            <IoLogoYoutube color="#000" size="1.4rem" />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
