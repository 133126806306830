import React, { useState, useEffect,useRef } from "react";
import { motion } from "framer-motion";
import url from "../getData";
import { ServicesDetail, Newsletter, Footer } from "../components";
import { Helmet } from "react-helmet";



const Servies = ({ match }) => {
  const [dataDetail, setData] = useState([]);
  useEffect(() => {
    getAboutPage();
    
  }, []);
  const getAboutPage = async () => {
    const fetchData = await fetch(`${url}posts/view`);
    const fetchRecord = await fetchData.json();

    const newDatas = await fetchRecord.filter(res => res.type == "about");
    setData(newDatas);
  };
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <motion.div
    initial="initial"
    animate="in"
    // exit="out"
    variants={pageVariants}
  >

<Helmet>
        <title>:: Services :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
        <link rel='canonical' href='/services' />
    </Helmet>

  <section className="Services">
   <ServicesDetail />
   
    </section>
    <Newsletter />
        <Footer />
    </motion.div>
  );
};

export default Servies;
