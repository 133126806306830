import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import "./header.css";
import hero from "../../images/hero.png";
import gsap from "gsap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Header = ({ toggleMenu, title, text, img, colors, headertext }) => {
  useEffect(() => {
    var anim = gsap.from(".left", {
      y: 100,
      duration: 1,
      stagger: 0.2,
      opacity: 0,
      delay: 1
    });
    var anim = gsap.from(".imgHover", {
      delay: 1.5,
      background: colors
    });

    var anim = gsap.to(".imgHover", {
      y: "-100%",
      duration: 1,
      stagger: 0.2,
      delay: 1.5,
      background: colors
    });
  }, []);
  return (
    <header className="header">
      <div className="header__left">
        <div className="header__leftcontent">
          {/* <h4 className="left">Cortext</h4> */}
          <h2
            className="left"
            dangerouslySetInnerHTML={{
              __html: title != "" ? title : "Our Story"
            }}
          />

          <p
            className="left"
            dangerouslySetInnerHTML={{ __html: headertext }}
          />
          <p className="left" dangerouslySetInnerHTML={{ __html: text }} />

          {/* <a
            href="#"
            className={`header__btn btn left ${toggleMenu ? "zi left" : ""}`}
          >
            Read More
          </a> */}
        </div>
      </div>
      <div className="header__right">
        <img src={img != "" ? img : hero} alt="Hero" />
        <div className="imgHover" style={{ background: colors }}></div>
      </div>
    </header>
  );
};
Header.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string
}

Header.defaultProps = {
  title: "Live environments <br> with any code commits",
  text: "Sharing is caring. With Release Environments, you can share development progress with every pull request.",
  colors: "#ef6236"
};

export default Header;
