import React, { useEffect, useState } from "react";
import { Header, Partners, Aboutdetail, Footer, Newsletter } from "../components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import url from "../getData";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";


const Work = ({setLoader}) => {
  const [dataDetail, setData] = useState([]);
  useEffect(() => {
    getAboutPage();
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: "body",
        markers: false,
        start: "top center+=100",
        end: "+=700" // end after scrolling 500px beyond the start
        // scrub: 1
      }
    });
    tl.from(".partner", {
      y: "100px",
      duration: 1,
      stagger: 0.2,
      opacity: 0,
      delay: 0.5
    });
  }, []);
  const getAboutPage = async () => {
    const fetchData = await fetch(`${url}posts/view`);
    const fetchRecord = await fetchData.json();

    const newDatas = await fetchRecord.filter(res => res.type == "work");
    setData(newDatas);
  };
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <motion.div
  initial="initial"
  animate="in"
  // exit="out"
  variants={pageVariants}
>
<Helmet>
        <title>:: Our Works Us :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
        <link rel='canonical' href='/work' />
    </Helmet>
      {/* <Header
        title={dataDetail[0] && dataDetail[0].title}
        text={dataDetail[0] && dataDetail[0].message}
        img={dataDetail[0] && "http://" + dataDetail[0].img.data}
        colors={dataDetail[0] && dataDetail[0].background}
      /> */}
      {/* <Aboutdetail bigtext={dataDetail[0] && dataDetail[0].bigtext} /> */}

      <Partners  className="partner" partner={"partner"} setLoader={setLoader}/>
      <Newsletter />
        <Footer />
    </motion.div>
  );
};

export default Work;
