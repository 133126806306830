const work = [
    {
        id: 1,
        type: 'Smart Car Wash Mobile App-Adobe XD UI Kit-20+ Screens',
        text: '',
        title: 'Smart Car Wash is a premium UI Kit. The main focus of this kit is to simplify your next car wash order.',
        background: '#eaf1fb',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2022/09/thumb_1080X1080.jpeg',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-0.jpg','https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-1.jpg',
    'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-2.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-3.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-4.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-5.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-6.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/09/01-–-7.jpg']
    },
    {
        id: 2,
        type: 'Brand Identity Design',
        text: '',
        title: 'Design brand guidelines and identity for Dirani – A magical touch for the elite only',
        background: '#fff',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2022/05/dirani.jpg',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.004.jpeg',
    'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.005.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.005.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.006.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.007.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.008.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.010.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Dirani-Logo-A.011.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Hanging-Wall-Sign-MockUp-3.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Plate-01.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Plate-02.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Window-Signage-MockUp-2.jpg']
    },
    {
        id: 3,
        type: 'Brand Identity Design',
        title: 'Design brand guidelines and identity for food delivery company.',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2022/05/foody.jpg',
        text: '',
        background: '#ef6236',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.002.jpeg',
    'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.004.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.005.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.006.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.007.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.008.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.009.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Foody-A.010.jpeg']
    }
    ,
    {
        id: 4,
        type: 'Brand Identity Design',
        title: 'Design brand guidelines and identity for momken.io',
        text: '',
        background: '#5f99a3',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2022/05/momken-thumb.jpg',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.001-1200x675.jpeg',
    'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.002-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.003-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.004-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.005-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.006-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.007-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.008-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.009-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.010-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.011-1200x675.jpeg',
'https://portfolio.waqart.com/wp-content/uploads/2022/05/Case-Study-Momken.012-1200x675.jpeg']
    }
    ,
    {
        id: 5,
        type: 'UX/UI Components',
        title: 'Daily UI is a series of daily Design Challenges Inspiration and new experiments!',
        text: '',
        background: '#3d257b',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2021/07/19.jpg',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2021/07/27.jpg',
    'https://portfolio.waqart.com/wp-content/uploads/2021/07/17.png',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/16.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/19.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/20.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/23.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/30.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/32.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/33.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/35.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/38.jpg',
'https://portfolio.waqart.com/wp-content/uploads/2021/07/39.jpg']
    },{
        id: 6,
        type: 'UI Kit',
        title: 'Free Mobile UI Kit To Track The Live Coronavirus World Meter.',
        text: `Simple UI kit design to get the latest and updated insights and stats for Covid-19. The design also includes a light version of this app.

        <br>
        <br>
        UI / UX
        <br>
        Visual Design
        <br>
        Creative Direction`,
        background: '#fd5a51',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2020/04/thumb.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/04/01-1200x953.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/02-1200x975.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/03-1200x1008.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/04-1200x1064.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/05-1200x980.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/06-1200x1821.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/06-1200x1821.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/04/07-1200x1419.jpg"]
    },{
        id:7,
        type: 'Mobile and Web Interface Design',
        title: 'Jumeirah Comes to the Marina',
        text: `<br>
        <br>
        UI / UX
        <br>
        Visual Design
        <br>
        Creative Direction`,
        background: '#a39360',
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2020/03/jumeirah-living.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/1-3-1200x798.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/2-3-1200x1041.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/3-3-1200x1262.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/4-2-1200x1143.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/5-3-1200x1235.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/6-2-1200x933.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/7-1-1200x1275.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/8-1-1200x863.jpg"]
        
    },
    {
        id:8,
        type: 'Puma Microsite Design',
        title: '#NoMatterWhat Challenge',
        background: '#f02601',
        text: `Dove wanted to promote their hair therapy product line on mobile, in line with their digital campaign #goodhairdays. Dove further wanted consumers to associate the brand with their hair care product range, since they are perceived to be more inclined to skin care.
<br/>
<br/>
        UI / UX
        <br/>
        Visual Design
        <br/>
        Creative Direction`,
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2020/03/puma.jpg',
        gallery: ['https://portfolio.waqart.com/wp-content/uploads/2020/03/01-1200x803.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/08-1200x681.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/02b-1200x675.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/03-1200x473.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/02-1200x435.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/04-1200x573.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/06-1200x675.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/web-01-1-1200x1473.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/web-02-1200x1486.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/11-1200x675.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/10-1200x675.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/09-1200x675.jpg',
        'https://portfolio.waqart.com/wp-content/uploads/2020/03/07-1200x675.jpg']
        
    }
    ,{
        id:9,
        type: 'Mobile App Design',
        title: 'Fitocial',
        background: '#00c6b7',
        text: `A mobile app concept that allow users to setup their fitness goal and find similar people in their area with the same objectives and goal. The app help them to connect, plan and perform all sports activities and share with their social circle. Because according to research you are more likely to achieve your goals when you share them with your friends and close circle. This is one of my approach to design a PSP (Problem Solving Product).
<br/>
        UI / UX
        <br/>
        Visual Design
        <br/>
        Creative Direction`,
        mainImg: ' https://portfolio.waqart.com/wp-content/uploads/2020/03/fitocial.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/01-1-1200x1971.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/02-1-1200x824.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/03-1-1200x746.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/04-1-1200x1730.jpg"]
       
    },
    {
        id:10,
        type: 'Mobile App Design',
        title: 'Dove Smile Alarm',
        background: '#023e96',
        text: `Dove wanted to promote their hair therapy product line on mobile, in line with their digital campaign #goodhairdays. Dove further wanted consumers to associate the brand with their hair care product range, since they are perceived to be more inclined to skin care.


<br/>
        UI / UX
        <br/>
        Visual Design
        <br/>
        Creative Direction`,
        mainImg: ' https://portfolio.waqart.com/wp-content/uploads/2020/03/dove-smile-alarm.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/1-1-1200x825.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/2-1-1200x1200.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/3-1-1200x1388.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/4-1-1200x1208.jpg",
    "https://portfolio.waqart.com/wp-content/uploads/2020/03/5-1-1200x1160.jpg",
"https://portfolio.waqart.com/wp-content/uploads/2020/03/6-1200x1160.jpg"]
       
    },
    ,
    {
        id:11,
        type: 'Awareness Campaign',
        title: 'Madinati Beeati',
        background: '#e1e1e1',
        text: `My City… My Environment is a green initiative embarked by Dubai Municipality with the goal at encouraging recycling and therefore reducing Dubai’s domestic waste.
<br/>
Art Direction
        <br/>
        Concept Development
        <br/>
        Digital Marketing`,
        mainImg: ' https://portfolio.waqart.com/wp-content/uploads/2020/03/mycity-myenvironment.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-1-copy-1200x1206.png",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-3-copy-1200x1262.png",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-4-copy-1200x1143.png",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-5-copy-1200x1235.png",
    "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-6-copy-1200x995.png",
"https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-7-copy-%E2%80%93-1-1200x1215.png",
'https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-7-copy-1200x955.png']
       
    },
    {
        id:12,
        type: 'Adaa Collection Brand Identity Design',
        title: 'Online fashion store for desi brands',
        background: '#ec478b',
        text: `I help Adaa Collection to come up with a brand name and brand identity that mainly focus on top women fashion brands from India and Pakistan. The brand focus on selling their products only through digital platforms and ecommerce store.
        <br>
        Brand Identity Design`,
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2020/03/Adaa-Collection.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/post-1200x800.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/1.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/2.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/3.jpg",
    "https://portfolio.waqart.com/wp-content/uploads/2020/03/4.jpg",
"https://portfolio.waqart.com/wp-content/uploads/2020/03/5.jpg"]
       
    },{
        id:13,
        type: 'Emirates Flight Catering Mobile App',
        title: 'Emirates Flight Catering UI/UX',
        background: '#d01f25',
        text: `A mobile app for Emirates Flight Catering. User can browse all the latest updates and news as well as can benefit from the exciting on board offers and special lounge access throughout the world.

        <br>
        UI / UX
        <br>
        Visual Design
        <br>
        Creative Direction


`,
        mainImg: 'https://portfolio.waqart.com/wp-content/uploads/2020/03/emirates-catering-3-1.jpg',
        gallery: ["https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-1-copy-1-1200x1206.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-3-copy-1-1200x1262.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-4-copy-1-1200x1143.jpg",
        "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-5-copy-1-1200x1235.jpg",
    "https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-6-copy-1-1200x995.jpg",
"https://portfolio.waqart.com/wp-content/uploads/2020/03/Artboard-7-copy-1-1200x955.jpg"]
       
    }
]

export default work;