import React, { useEffect, useState } from "react";
import { Header, Partners, Aboutdetail, Newsletter, Footer } from "../components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import url from "../getData";
import aboutone from "./../images/about.webp";
import abouttwo from "./../images/about-03.webp";
import aboutlanding from "./../images/about-02.webp";
import { Helmet } from "react-helmet";


const About = ({ match }) => {
  const [dataDetail, setData] = useState([]);
  useEffect(() => {
    getAboutPage();
    
    
  }, []);
  const getAboutPage = async () => {
    const fetchData = await fetch(`${url}posts/view`);
    const fetchRecord = await fetchData.json();

    const newDatas = await fetchRecord.filter(res => res.type == "about");
    setData(newDatas);
  };
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <motion.div
    initial="initial"
    animate="in"
    // exit="out"
    variants={pageVariants}
  >
  <Helmet>
        <title>:: About Us :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
        <link rel='canonical' href='/about' />

    </Helmet>
      {/* <Header
        title={"we are an agency of change"}
        text={`Staying at the forefront of positive change with agility, excellence and quality.
        We realise innovative ideas across all media channels for clients and brands around the world.
      `}
        img={"https://portfolio.waqart.com/wp-content/uploads/2021/07/19.jpg"}
        colors={"#3d257b"}
      /> */}

      <Aboutdetail imgOne = {aboutone} imgTwo = {abouttwo} imgthree={aboutlanding} bigtext={`Pixmestudio is a digital design studio based in Dubai. We build hybrid design systems with immersive user experience that seamlessly blend brand expression and product experience, bringing brands to technology and making people love to interact with. Our Team creates an exceptional visualization and thought-out functionality. `} />
      {/* <Partners /> */}
      <Newsletter />
        <Footer />
    </motion.div>
  );
};

export default About;
