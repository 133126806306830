import React from 'react';
import { AnimatePresence } from "framer-motion";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteProps,
    useLocation,  
  } from "react-router-dom";
  import { Home, About, Work, Servies, Contact } from "./../pages";
  import {WorkDetail} from "./../components";
const AnimatedRoute = ({toggleMenu, setLoader}) => {
    const location = useLocation();
  return (
   
    <AnimatePresence
    exitBeforeEnter
    initial={false}
    onExitComplete={() => {
      if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0 })
      }
    }}
  >
       

     <Switch location={location} key={location.pathname}>
            <Route path="/" exact>
              <Home toggleMenu={toggleMenu} />
            </Route>

            <Route path="/about" exact>
              <About />
            </Route>
            <Route path="/work" exact >
              <Work/>
            </Route>

            <Route path="/services" exact>
              <Servies />
            </Route>

            <Route path="/contact" exact>
              <Contact />
            </Route>
            
            <Route path="/work/:id" exact>
              <WorkDetail/>
            </Route>
          </Switch>
    </AnimatePresence>
  )
}

export default AnimatedRoute;