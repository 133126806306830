import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./partners.css";
import first from "../../images/liancu.jpeg";
import second from "../../images/hope-factory.jpeg";
import third from "../../images/7-eleven-1.jpeg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import url from "../../getData";
import works from "./../../data/work";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const Partners = ({limit, viewBtn, partner, setLoader}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchWorks();
    // let tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".partners",
    //     markers: false,
    //     start: "top center+=100",
    //     end: "+=700" // end after scrolling 500px beyond the start
    //     // scrub: 1
    //   }
    // });
    // tl.from(".partners__title", {
    //   x: -400,
    //   opacity: 0,
    //   duration: 0.6,
    //   ease: "Power3.easeOut",
    //   delay: 0.5
    // });
    // tl.from(".partners__item", {
    //   y: 400,
    //   opacity: 0,
    //   duration: 1,
    //   ease: "Power3.easeOut",
    //   stagger: 0.3,
    //   delay: 1
    // });
  }, []);

  const fetchWorks = async () => {
    const data = await fetch(`${url}works/viewall`);
    const response = await data.json();
    setData(response);
  };

  const changeHandler = () => {
    console.log("first");
  }

  

  return (
    <section className="partners">
     <Helmet>
        <title>::Our Work :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
    </Helmet>
      <div className="partners__title">
        <div className={`partners__heading ${partner}`}>
          The work
          <span> we do</span>
        </div>
        <div className="partners__btn">
          {viewBtn ?  <Link to={`/work`}>View All</Link> : ''}
       
        </div>
      </div>
      <div className="container partners__grid">
        {/* Partners iTEM */}
        
        {works.length && works.slice(0,limit).map((work, index) => {
          return (
            <div className={`partners__item ${partner}`} key={index}>
              <div className="partners__img" >
              <Link to={`/work/${work.id}`} onClick={changeHandler} > <img
                  src={work.mainImg}
                  onClick={changeHandler}
                  alt="first"
                  className="img-fulid"
                /></Link>
              </div>
              <h6 className="partners__relase">{work.type}</h6>
              <div className="partners__head" onClick={changeHandler}>
                <Link to={`/work/${work.id}`} >{work.title}</Link>
              </div>
              {/* <div className="partners__tags">
            <a href="#">SEQUOIA</a>
            <a href="#">Y COMBINATOR</a>
          </div> */}
            </div>
          )
        })}
      </div>
    </section>
  );
};

export default Partners;
