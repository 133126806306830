import React, {useEffect} from 'react';
import "./service.css";
import { Link } from "react-scroll";

import {
    web,
    branding,
    desgin,
    desginB,
    brandingMain,
    webimg,
    jquery,
    Imgp,
    yii,
    mysql,
    psotsql,
    mongo,
    drupal,
    joomla,
    magento,
    shopify,
    wordpress
} from "./index";

import gsap from "gsap";
import {
    IoSearchOutline,
    IoColorPaletteOutline,
    IoLaptopOutline,
    IoFlaskOutline,
    IoRocketOutline,
    IoCallOutline,
    IoLogoReact,
    IoLogoHtml5,
    IoLogoCss3,
    IoLogoSass,
    IoLogoJavascript,
    IoLogoNodejs,
    IoLogoLaravel,
    IoLogoPython
  } from "react-icons/io5";




const ServicesDetail = () => {

  useEffect(() => {
    scrollEachItem(".anim");
  }, []);
    
  const scrollEachItem = (details) => {
    const items = document.querySelectorAll(details);
    items.forEach(item => {
      const gsapData = gsap.from(item,{
        scrollTrigger:{
          trigger: item,

        },
        y : "100px",
        opacity:0,
        ease: "power1.inOut",
        duration: 1
      });

      return gsapData;
      
    })
}
  return (
    <section className='services '>
     <h1 className='service__subtitle anim'>How we can support you?</h1>
     {/* Service Icons */}
     <div className='service__icons anim'>
    
        <div className="service__items">
        <Link
      activeClass="active"
      to="desgin"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>
       
            <img src={desgin}  alt="Design"/>
         </Link>
            <h3 className='text-center'> 
            <Link
      activeClass="active"
      to="desgin"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>
            Design</Link></h3>
           
        </div>
        
      
        <div className="service__items ">
        <Link
      activeClass="active"
      to="branding"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>
            <img src={branding}  alt="Branding & Marketing"/>
            </Link>
            <h3 className='text-center'> <Link
      activeClass="active"
      to="branding"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>Branding & Marketing</Link></h3>
        </div>
       
       
        <div className="service__items ">
        <Link
      activeClass="active"
      to="web"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>
            <img src={web}  alt="Web Development"/>
            </Link>
            <h3 className='text-center'> 
            <Link
      activeClass="active"
      to="web"
     spy={true}
     smooth={true}
     offset={-100}
     duration={500}>
            Web Development</Link></h3>
        </div>
     
     </div>
{/* Services List */}
   <div className="services__list anim" id="desgin">
    <div className='services__left'>
        <img src={desginB}  alt="Branding"/>
    </div>
    <div className='services__right'>
        <h2>Design</h2>
        <p>Great products do more than just work. We design
Digitl experiences that balance utility and expression to create
More meaningful interactions.</p>
        <ul>
            <li>Creative Direction</li>
            <li>Art Direction</li>
            <li>Visual Identity</li>
            <li>Motion Design</li>
            <li>Social Media Design</li>
            <li>SEO / SEM</li>
            <li>Google Ads</li>
            <li>Facebook & Instagram Ads</li>
           
        </ul>
    </div>
   </div>
   {/* Services List */}
   <div className="services__list services__reverse anim" id="branding">
    <div className='services__left'>
        <img src={brandingMain}  alt="Branding"/>
    </div>
    <div className='services__right'>
        <h2>Branding & Marketing</h2>
        <p>We design brand systems fit for the rigors of the digital era,
Merging brand identity and product UI to bring consistency to
Every touchpoint.</p>
        <ul>
            <li>Creative Direction</li>
            <li>Art Direction</li>
            <li>Visual Identity</li>
            <li>Motion Design</li>
            <li>Social Media Design</li>
            <li>SEO / SEM</li>
            <li>Google Ads</li>
            <li>Facebook & Instagram Ads</li>
           
        </ul>
    </div>
   </div>
   {/* Services List */}
   <div className="services__list anim pb-30" id="web">
    <div className='services__left'>
        <img src={webimg}  alt="Branding"/>
    </div>
    <div className='services__right'>
        <h2>Web Development</h2>
        <p>We carefully design inclusive digital experiences.
We help prototype and test products and create a
powerful interface that seamlessly builds on
brand strategy and company goals.</p>
        <ul>
            <li>Wire-framing</li>
            <li>Prototypes</li>
            <li>iOS Application Design</li>
            <li>Android Application Design</li>
            <li>Front-End Development</li>
            <li>Back-End Development</li>
            <li>Hybrid Apps Development</li>
            <li>Quality Assurance</li>
            <li>Progressive Web Apps</li>
            <li>E-Commerce</li>
           
        </ul>
    </div>
   </div>

   <div className="services__flow anim">
     <h2 className='text-centers'>Our Flow</h2>
     <div className="services__flowlist">
     <div className="services__flowitem">
        <div className='icon'>
        <IoSearchOutline  color="#000" size="3rem"/>
        </div>
        <h3>Research</h3>
        <p>The starting point of any project is research. Its main three aspect are user flow, target audience and the product niche segmentation.
</p>
        </div>
        {/* Item Ends */}
        <div className="services__flowitem">
            <div className="icon">
                <IoColorPaletteOutline  color="#000" size="3rem"/>
            </div>
        <h3>Design</h3>
        <p>The design process is as follows: Prototype-UX design and UI design. They are based on preliminary research and customers brief.</p>
        </div>
        {/* Item Ends */}
        <div className="services__flowitem">
        <div className="icon">
                <IoLaptopOutline  color="#000" size="3rem"/>
            </div>
        <h3>Development</h3>
        <p>Once the design is approved, we hand over the product to our dev team. There we work with different framework libraries and programming languages.</p>
        </div>
        <div className="services__flowitem">
        <div className="icon">
                <IoFlaskOutline  color="#000" size="3rem"/>
            </div>
        <h3>Testing</h3>
        <p>The most important stage of any product creation is testing. The main type of testing: QA, responsive testing for all devices and code-review.
</p>
        </div>
        {/* Item Ends */}
        <div className="services__flowitem">
        <div className="icon">
                <IoRocketOutline  color="#000" size="3rem"/>
            </div>
        <h3>Launch</h3>
        <p>When a product successfully passes all kinds of testing, we launch it on the market. There we already see how it interacts with the target audience.</p>
        </div>
        {/* Item Ends */}

        <div className="services__flowitem">
        <div className="icon">
                <IoCallOutline  color="#000" size="3rem"/>
            </div>
        <h3>Support</h3>
        <p>Quality in detail, competence in the ability to communicate after the project implementation. We are glad to provide our clients with the necessary support.</p>
        </div>
        {/* Item Ends */}
        
   </div>
   </div>
   <div className="tech anim">
    <h2 className='text-centers'>Technologies</h2>
    <div className="tech__items">
        {/* <h3>Front-End</h3> */}
        <div className='tech__list'>
        <a href="#" alt="HTML 5" title='CSS 3'>
        <IoLogoHtml5 color="#e44d26" size="7rem"/>
        </a>
       
        </div>

        <div className='tech__list'>
        <a href="#" alt="CSS 3" title='CSS 3'>
        <IoLogoCss3 color="#264de4" size="7rem"/>
        </a>
       
        </div>

        <div className='tech__list'>
        <a href="#" alt="Javascript" title='Javascript'>
        <IoLogoJavascript color="#e4d04b" size="7rem"/>
        </a>
       
        </div>

        <div className='tech__list'>
        <a href="#" alt="Jquery" title='Jquery'>
        <img src={jquery} alt="Jquery" width="150px" />
        </a>
        
        </div>
        
        <div className='tech__list'>
        <a href="#" alt="Sass" title='Sass'>
        <IoLogoSass color="#cf649a" size="7rem"/>
        </a>
       
        </div>
        <div className='tech__list'>
        <a href="#" alt="React" title='React'>
        <IoLogoReact color="#5ccfee" size="7rem"/>
        </a>
       
        </div>
        

        

        <div className='tech__list'>
        <a href="#" alt="Php" title='Php'>
        <img src={Imgp} alt="Php" width="150px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Yii Framework" title='Yii Framework'>
        <img src={yii} alt="Yii" width="100px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Node Js" title='Node Js'>
        <IoLogoNodejs color="#3fb984" size="7rem"/>
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Python" title='Python'>
        <IoLogoPython color="#ffcd3a" size="7rem"/>
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Laravel" title='Laravel'>
        <IoLogoLaravel color="#e71414" size="7rem"/>
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Mysql" title='Mysql'>
        <img src={mysql} alt="Mysql" width="200px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Postsql" title='Postsql'>
        <img src={psotsql} alt="Postsql" width="150px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Mongo" title='Mongo'>
        <img src={mongo} alt="Mongo" width="150px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Drupal" title='Drupal'>
        <img src={drupal} alt="Drupal" width="150px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Joomla" title='Joomla'>
        <img src={joomla} alt="Joomla" width="200px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Magento" title='Magento'>
        <img src={magento} alt="Magento" width="200px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Shopify" title='Shopify'>
        <img src={shopify} alt="Shopify" width="200px" />
        </a>
        
        </div>

        <div className='tech__list'>
        <a href="#" alt="Wordpress CMS" title='Wordpress CMS'>
        <img src={wordpress} alt="Wordpress" width="100px" />
        </a>
        
        </div>

        

        
        
       
    </div>
   </div>
    </section>
  )
}

export default ServicesDetail;