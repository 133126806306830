import React from "react";
import "./capabilities.css";

const Capabilities = () => {
  return (
    <section className="capabilities" id="capabilities">
      <h2 className="heading-32">Capabilities</h2>
      <p className="p20">Broad expertise for your scaling needs</p>
      <div className="capabilities__grid">
        <div className="capabilities__item">
          <h3 className="heading-24">Brand Strategy</h3>
          <p className="p18">
            Discovery
            <br />
            Hypothesis Validation
            <br />
            Story Driven Personification
            <br />
            Brand Imperatives
            <br />
            Mission, Vision &amp; Values
            <br />
            Visual Identity
            <br />
            Positioning
            <br />
            Brand Guideline
          </p>
        </div>

        <div className="capabilities__item">
          <h3 className="heading-24">Product Design</h3>
          <p className="p18">
            User Research
            <br />
            UX Audit
            <br />
            UI/UX Design
            <br />
            Copywriting
            <br />
            Prototyping
            <br />
            Web Design
            <br />
            Design Systems
            <br />
            Pitch Deck
          </p>
        </div>

        <div className="capabilities__item">
          <h3 className="heading-24">Engineering</h3>
          <p className="p18">
            HTML/CSS/JS
            <br />
            React/Angular
            <br />
            API integrations
            <br />
            iOS/Android native app
            <br />
            Webflow Development
            <br />
            iOS Applications
          </p>
        </div>

        <div className="capabilities__item">
          <h3 className="heading-24">Content</h3>
          <p className="p18">
            Script Development
            <br />
            Copywriting
            <br />
            Storyboarding
            <br />
            Explainer Video
            <br />
            Brand Photography
            <br />
            Illustration
            <br />
            Animation
          </p>
        </div>
      </div>
    </section>
  );
};

export default Capabilities;
