import React, {useState, useEffect} from 'react';
import { motion } from "framer-motion";
import { Newsletter, Footer } from '../components';
import gsap from "gsap";
import { Helmet } from 'react-helmet';


const Contact = () => {
    const [data , setData] = useState({
        username: '',
        email: '',
        content: ''
    });
    const [message, setMessage] = useState("");
    const [showerror, setShow] = useState(false);
    const dataHandler = (e) => {
        e.preventDefault();
        
         
        setData({...data, [e.target.name] : e.target.value});
        
        
    }

    useEffect(() => {
      scrollEachItem(".contact-anim");
    }, []);

    const clickHandler = (e) => {
        e.preventDefault();
        if(data.username && data.email){
          sendData();
        } else {
          setShow(true);
          setMessage("Please Fill The Form Correctly");

        }
       
        setData({username: '',
        email: '',
        content: ''});
       
    }



    const sendData = () => {
        

        fetch('https://portfolio.waqart.com/react/', {
  method: 'POST',
  mode: 'cors',
  headers : {
    'Content-Type': 'application/x-www-form-urlencoded',
},
  body: JSON.stringify(data),
})
.then(data => data.ok && data.json())
.then(response => setMessage(response));

 }

 const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const scrollEachItem = (details) => {
  const items = document.querySelectorAll(details);
  items.forEach(item => {
    console.log(item);
    const gsapData = gsap.from(item,{
      scrollTrigger:{
        trigger: item,

      },
      y : "100px",
      opacity:0,
      ease: "power1.inOut",
      duration: 1
    });

    return gsapData;
    
  })


}

  return (
    <motion.div
  initial="initial"
  animate="in"
  // exit="out"
  variants={pageVariants}
>
<Helmet>
        <title>:: Contact Us :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
        <link rel='canonical' href='/contact' />

    </Helmet>
    <section className="contactus">
        <div className="contactus__col">
       <div className="contactus__left contact-anim">
        <h4>CONTACTS</h4>
        <h3><a href="mailto:info@pixmestudio.com">info@pixmestudio.com</a></h3>
        <h3><a href="tel:+971507149070">+971-50-714-9070</a></h3>

        </div>    
        <div className="contactus__right contact-anim">
        <h4>Hire Us</h4>
        <h2>A project with pixmestudio?</h2>
        <div className="contactus__form">
            <form method='POST'>
                <div className="contactus__group">
                <div className="input__group">
                <input type="text" name="username" placeholder="Name" onChange={dataHandler} value={data.username}  required/>
               
                </div>
                <div className="input__group">
                <input type="email" name="email" placeholder="Email"  onChange={dataHandler} value={data.email} required/>
               
                </div>
                </div>
                <div className="input__group">
                    <textarea name='content' placeholder="Project details(optional)" col="6" onChange={dataHandler} value={data.content}></textarea>
                </div>
                <button className='btn__send' onClick={clickHandler}>Send</button>
            </form>
            {message && (
          <h4 className={showerror ? 'messageAdded red': 'messageAdded'}>{message}</h4>
        )}
        </div>
        </div>
        </div>
    </section>
    <Newsletter />
        <Footer />
    </motion.div>
  )
}

export default Contact