import React, { useEffect, useState } from "react";
import {
  Header,
  Intro,
  Partners,
  Apporach,
  Engagements,
  Capabilities,
  Newsletter,Footer
} from "../components";
import {motion} from "framer-motion";
import url from "../getData";
import data from "./../data/data";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";


const Home = ({ toggleMenu }) => {

  
  
  const [dataDetail, setData] = useState([]);
  useEffect(() => {
    getAboutPage();
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: "partner",
        markers: false,
        start: "top center+=100",
        end: "+=700" // end after scrolling 500px beyond the start
        // scrub: 1
      }
    });
    tl.from(".partner", {
      y: "100px",
      duration: 1,
      stagger: 0.2,
      opacity: 0,
      delay: 0.5
    });
    
  }, []);
  const getAboutPage = async () => {
    const fetchData = await fetch(`${url}posts/view`);
    const fetchRecord = await fetchData.json();

    const newDatas = await fetchRecord.filter(res => res.type == "home");
    setData(newDatas);
  };
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }

  return (

    <motion.div
  initial="initial"
  animate="in"
  // exit="out"
  variants={pageVariants}
>
<Helmet>
        <title>:: Home :: Pixmestudio</title>
        <meta name="description" content="Pixmestudio Free Website" />
        <link rel='canonical' href='/' />


    </Helmet>
      <Header
        toggleMenu={toggleMenu}
        title={"We are an agency of change"}
        text={`Pixmestudio is a Dubai based small studio of experienced digital designers
        & strategists that are fascinated by future-forward ideas.`}
        img={"https://portfolio.waqart.com/wp-content/uploads/2022/05/foody.jpg"}
        colors={dataDetail[0] && dataDetail[0].background}
      />
      <Intro bigtitle={`We are not a traditional agency or a digital agency
or a social agency we are a change agency`} bigtext={`One that believes in the power of creativity to have a transformational effect on companies, brands and communities. Bring us your biggest problem. That's all we ask.`} />
      <Partners limit = {6} viewBtn= {true} partner={"partner"}/>
      <Apporach />
      {/* <Engagements /> */}
      <Capabilities />
      <Newsletter />
        <Footer />
    </motion.div>
  );
};

export default Home;
