import React from "react";
import "./newsletter.css";
import arrowleft from "../../images/arrow.svg";

const Newsletter = () => {
  return (
    <section className="newsletter " id="newsletter">
      <div className="container-fuild">
        <div className="newsletter__content">
          <h4>Join the network</h4>
          <p className="p20">
          Get insights, inspiration and updates.
We never send spams.
          </p>
          <form action="#" method="post">
            <input
              type="email"
              placeholder="Your email"
              className="newsletter__input"
            />
            <button>
              Signup{" "}
              <span>
                <img src={arrowleft} alt="Arrow" />
              </span>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
