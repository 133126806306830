import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router, HashRouter} from "react-router-dom";
import locomotiveScroll from "locomotive-scroll";



import {
  Navbar,
  Newsletter,
  Footer 
} from "./components";
import AnimatedRoute from "./components/AnimatedRoute";
import ScrollToTop from "./components/ScrollToTop";



function App() {
  const [toggleMenu, setToggle] = useState(false);
  const scrollRef = React.createRef();

  


  
  return (
    <HashRouter basename={"/"}>
      
    <Router>
     
      {/* {newload && 
      <ScrollToTop />
      }
     */}
      <div className="App">
        <Navbar toggleMenu={toggleMenu} setToggle={setToggle}  />
       <AnimatedRoute toggleMenu={toggleMenu}  />
        
      </div>
    </Router>
    
    </HashRouter>
  );
}

export default App;
