import React from "react";
import "./apporach.css";
import circle from "../../images/circle.svg";
import share from "../../images/careers-success-logo.svg";
import three from "../../images/3.svg";
import four from "../../images/4.svg";
import five from "../../images/5.svg";
import six from "../../images/6.svg";


const Apporach = () => {
  return (
    <section id="approch" className="approch">
      <div className="approch__sidebar">
        <h2>Approach</h2>
        <p>
          Pixmestudio uniquely integrates with seed to growth stage startups in
          support of their brand and product design needs.
        </p>
      </div>
      <div className="approch__grid">
        <div className="approch__items">
          <div className="approch__img">
            <img src={share} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">EQUITY ENGAGEMENT</div>
            <div className="approch__title">Shared success</div>
            <div className="approch__content">
            For Seed startups—where potential is more abundant than capital—we invest creative expertise in exchange for equity, providing startups with mainstay access to the design support essential to success. Like a valued co-founder with aligned incentives and mutual commitment to your success.
            </div>
          </div>
        </div>
        <div className="approch__items">
          <div className="approch__img">
            <img src={circle} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">INTEGRATED APPROACH</div>
            <div className="approch__title">Team of integrated product experts</div>
            <div className="approch__content">
            It takes a process-oriented team of embedded experts to effectively navigate each stage of product iteration. We attracts best-in-class design and engineering talent from around the globe to work with the world’s next-best companies.


            </div>
          </div>
        </div>
        <div className="approch__items">
          <div className="approch__img">
            <img src={three} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">FOUNDER FOCUSED
</div>
            <div className="approch__title">Selective partnership
</div>
            <div className="approch__content">
            We inhabit the startup realm and therefore respect the intense struggle of the entrepreneurial process. We form partnerships selectively and make commitments to our founders—to honor their vision, optimize their experience, and deliver world-class products.


            </div>
          </div>
        </div>
        <div className="approch__items">
          <div className="approch__img">
            <img src={four} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">GROWTH ORIENTED
</div>
            <div className="approch__title">Scalable design support
</div>
            <div className="approch__content">
              Pixmestudio delivers agile expertise to help you scale, eliminating resource-intensive hiring, onboarding, and management, as well as the skillset limitations of individual in-house designers. From your first design hire to your fifth, we meet your multidisciplinary design needs at every stage of the startup spectrum.
            </div>
          </div>
        </div>
        <div className="approch__items">
          <div className="approch__img">
            <img src={five} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">STORY DRIVEN</div>
            <div className="approch__title">Designed with intention
</div>
            <div className="approch__content">
            Our formulary processes guide the evolution of startup identity and product design. We prioritizes strategy, analytics, and integrity to hone the stories we help tell. From this foundation, we weave authentic and compelling brand and product experiences.


            </div>
          </div>
        </div>
        <div className="approch__items">
          <div className="approch__img">
            <img src={six} alt="Circle" className="approch__icons" />
          </div>
          <div className="contentWrap">
            <div className="approch__subtitle">LASTING IMPACT
</div>
            <div className="approch__title">Long-term relationships
</div>
            <div className="approch__content">
            Where most agencies offer short-lived, transactional collaboration, the We team dovetails with your own for a long-term, integrated partnership. We focus on your success, rather than on deliverables, and in doing so add immeasurable integrity and value to your design output.


            </div>
          </div>
        </div>
      
      </div>
    </section>
  );
};

export default Apporach;
