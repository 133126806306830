import React, { useState, useRef, useEffect } from "react";
import "./navbar.css";
import pixmeSmall from "./../../images/pixmestudio_symbol_black@500.png";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoYoutube
} from "react-icons/io5";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import Logo from "../../images/pixmestudio_main_logo@500.png";

const Navbar = ({ toggleMenu, setToggle,  }) => {
  const [isActive, isActivefnc] = useState(false);
  const toggleHandler = () => {
    setToggle(!toggleMenu);
    
  };

  const toggleHandlers = () => {
    setToggle(!toggleMenu);
    
  };

  const logoHandler = () => {
    setToggle(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 20) {
      isActivefnc(true);
    } else {
      isActivefnc(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (toggleMenu) {
      gsap.to(".burger__menuitem ul li", {
        y: 40,
        duration: 0.5,
        stagger: 0.2,
        opacity: 1,
        delay: 0.5
      });
    } else {
      gsap.to(".burger__menuitem ul li", {
        y: 0,
        duration: 0.3,
        stagger: 0,
        opacity: 0,
        delay: 0
      });
    }
  });

  return (
    <nav className={isActive ? "navbar active" : "navbar"}>
      <div className="navbar__container">
        <div className="navbar__logo">
          <h1>
            {" "}
            <Link to="/" onClick={logoHandler}>
              <img src={isActive ? pixmeSmall : Logo} alt="" width="150px" />
            </Link>
          </h1>
        </div>
        <div
          className={`navbar__menu ${toggleMenu ? "open" : ""} `}
          onClick={toggleHandlers}
        >
          <div className= {isActive ? "active" : "wrap"}>
          <span></span>
          <span></span>
          <span></span>
          </div>
        </div>
      </div>
      <div className={`BurgerMenu ${toggleMenu ? "active" : ""}`}>
        <div className="burger__menuitem">
          <ul>
            <li>
              <Link to="/about" onClick={toggleHandler}>
                About
              </Link>
            </li>
            <li>
              <Link to="/work" onClick={toggleHandler}>
                Work
              </Link>
            </li>
            <li>
            <Link to="/services" onClick={toggleHandler}>
            Services
              </Link>
            </li>
            <li>
            <Link to="/contact" onClick={toggleHandler}>
                Contact
              </Link>
            </li>
           
          </ul>
          {/* <div className="social-icon">
            <a href="#">
              <IoLogoFacebook color="#000" size="1.4rem" />
            </a>
            <a href="#">
              <IoLogoTwitter color="#000" size="1.4rem" />
            </a>
            <a href="#">
              <IoLogoLinkedin color="#000" size="1.4rem" />
            </a>
            <a href="#">
              <IoLogoYoutube color="#000" size="1.4rem" />
            </a>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
