import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../header/Header";
import Loader from "../Loader";
import OwlCarousel from "react-owl-carousel";
import { motion } from "framer-motion";
import { pageAnimation } from "../../animation";
import url from "../../getData";
import work from "./../../data/work";
import {Newsletter, Footer} from "../index";


import "./work.css";
import { Helmet } from "react-helmet";

const WorkDetail = ({ match , name }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setData] = useState();
  const [workstate, worksState] = useState([]);

  const parms = useParams();
    const { id } = useParams();
  useEffect(() => {
   
    
    setTimeout(()=> {
      setIsLoading(!isLoading);
    }, 3000);
    const Works = work.filter(singleWork => singleWork.id == id);

    worksState(Works);
     
    fetchWorks();
  }, []);
  const fetchWorks = async () => {
    const data = await fetch(`${url}works/viewall`);
    const response = await data.json();
    const detailPage = response.filter(resp => {
      return resp._id == id;
    });
    setData(detailPage);
  };
  const [content] = workstate;
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
   
    <motion.div
    initial="initial"
    animate="in"
    // exit="out"
    variants={pageVariants}
  >
      
        {workstate.length && (
          <>
          <Helmet>
        <title>{content.title}</title>
        <meta name="description" content="Pixmestudio Free Website" />
    </Helmet>
          <Header
              key={content.id}
              title={content.title}
              text={content.text}
              img={content.mainImg}
              colors={content.background}
              headertext={content.type}
            />
            <section className="work">
              <div className="container-fuild">
                <div className="work__titlewrap">
                  <div className="work__sidebar">
                    <div className="work__boxleft">
                      <div className="work__box">
                        <span className="work__key">Client</span>
                        <span className="work__val">{content.type}</span>
                      </div>
                      <div className="work__box">
                        <span className="work__key">Year</span>
                        <span className="work__val">{content.type}</span>
                      </div>
                    </div>
                    <div className="work__boxright">
                      <div
                        className="work__box"
                        dangerouslySetInnerHTML={{ __html: content.type }}
                      />
                    </div>
                  </div>
                  <div className="work__Innertitle">
                    <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
                    <p dangerouslySetInnerHTML={{ __html: content.type }} />
                  </div>
                </div>
                <div className="work__img">
                  {content.gallery.map((resp, index) => {
                    return (
                      <img
                        key={index}
                        src={resp}
                        alt="Image"
                        width="100%"
                      />
                    );
                  })}
                </div>
              </div>
              {/* <OwlCarousel
                className="owl-theme"
                loop
                margin={30}
                items={3}
                autoplay={true}
              >
                {content.gallery.map((resp, index) => {
                  return (
                    <div className="item" key={index}>
                      <img
                        src={resp}
                        alt=""
                        width="100%"
                      />
                    </div>
                  );
                })}
              </OwlCarousel> */}
            </section>
            <Newsletter />
        <Footer />
            </>
            
        )}
    </motion.div>
  );
};

export default WorkDetail;
